<template>
    <nav class="flex items-center justify-between bg-white py-4 space-x-4" aria-label="Pagination">
      <div class="block">
        <p class="text-xs md:text-sm text-gray-700">
          {{ $t(`home.${entity}`) }} {{ $t('pagination.from') }} <span class="font-medium">{{pagination.from}}</span> {{ $t('pagination.to') }} <span class="font-medium">{{pagination.to}}</span> {{ $t('pagination.of') }}
          <span class="font-medium">{{pagination.total}}</span> {{ $t('pagination.total') }}
        </p>
      </div>
      <div class="flex flex-1 justify-between sm:justify-end">
        <button v-if="pagination" @click="prev" :class="`${!pagination['hydra:previous'] ? 'opacity-50 pointer-events-none' : ''} w-20 justify-center relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`"><ChevronLeftIcon class="h-5 w-5" aria-hidden="true" /></button>
        <button v-if="pagination" @click="next" :class="`${!pagination['hydra:next'] ? 'opacity-50 pointer-events-none' : ''} w-20 justify-center relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0`"><ChevronRightIcon class="h-5 w-5" aria-hidden="true" /></button>
      </div>
    </nav>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'

export default {
    components: {
      ChevronLeftIcon,
      ChevronRightIcon
    },
    props: {
      entity: {
          type: String,
          default: 'installations',
          required: false
      },
      status: {
          type: String,
          required: false
      },
      storeKey: {
          type: String,
          required: false
      },
    },
    computed: {
        pagination() {
          const collection = this.$store.state[this.entity]
          const key = this.storeKey ? this.storeKey : (this.status ? this.status : null)
          return key ? collection[key].pagination : collection.pagination
        }
    },
    methods: {
        async to(page) {
          this.$store.dispatch('setLoading', true)
          await this.$store.dispatch('getCollection', {entity: this.entity, key: this.storeKey, page, status: this.status})
          this.$store.dispatch('setLoading', false)
        },
        prev() {
          this.to(this.pagination.currentPage - 1)  
        },
        next() {
          this.to(this.pagination.currentPage + 1) 
        }
    }
};
</script>